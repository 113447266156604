
import React from 'react';
import './App.css';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation.js'
import Footer from './Footer.js'



class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      defaultRootSize: 1, 
      rootSize: 16,
      pxSize: 16,
      remSize: 1,

    }

    this.calculate = this.calculate.bind(this)
  }

  calculate(e) {
   
      // Attempt to parse the size from the e input
      let parsedSize = (e.target.value.length ? e.target.value : this.state.defaultRootSize);
      
      // Find the target and set the appropriate data value
      switch(e.target.getAttribute('name')) {

        case 'root_size':
            this.setState({
              rootSize: parsedSize 
            },  () => {
              this.setState({
                pxSize: (this.state.rootSize * this.state.remSize).toFixed(2)
              })
            });
            break;
        
        case 'required_size':
          this.setState({
            remSize: parsedSize
          }, () => {
            this.setState({
              pxSize: (this.state.rootSize * this.state.remSize).toFixed(2)
            })
          });
          break;
      }

       
  
  }


  render() {
    return (
      <div>

        <header>
            <NavLink to="/"><img src="/img/remtopx-logo.png" className="logo" alt="RemtoPx Converter Logo" /></NavLink>
    
            <nav className="menu">
            <label htmlFor="toggle">&#9776;</label>
            <input type="checkbox" id="toggle" />
                <div id="dd-menu">
                <Navigation />
                </div>
            </nav>
        </header>

        <div className="container">
          <h1 className="title">REM to PX Converter</h1>
          <h2>Convert your REM Value to Pixels</h2>
          <h3>Default Root Font Size (px) </h3>
          <input type="number" name="root_size" id="root-size" placeholder="16" onChange={this.calculate}/>
        </div>

      <div className="container">
        <div className="converter-container">

        <label>Select or Type your Rem
          <div className="numeric">
            <input type="number" name="required_size" id="required-size" className="input" placeholder="1" onChange={this.calculate} />
            <abbr className="rem-unit">rem</abbr>
          </div>
        </label>

        <div className="equals"> = </div>
        <label>Result in Pixels
          <div className="numeric">
            <input id="red" value={this.state.pxSize}  className="input" />
            <abbr className="px-unit">px</abbr>
          </div>
        </label>
        </div>

        <NavLink to="/px-to-rem-converter"><img src="/img/switch.png" id="large-switch"  alt="Switch Logo" /></NavLink>

        <p>Click the <NavLink to="/px-to-rem-converter"><img src="/img/switch.png" id="small-switch"  alt="Switch Logo" /></NavLink> icon to switch to <b>PX to REM Converter.</b></p>

      </div>
      <Footer />
    </div>
    );
  }
}

export default Home;
