
import React from 'react';
import './App.css';
import { NavLink } from 'react-router-dom';
import Navigation from './Navigation.js'
import Footer from './Footer.js'



class PXConverter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      defaultRootSize: 16, 
      rootSize: 16,
      pxSize: 16,
      remSize: 1,

    }

    this.calculate = this.calculate.bind(this)
  }

  calculate(e) {
   
      // Attempt to parse the size from the e input
      let parsedSize = (e.target.value.length ? e.target.value : this.state.defaultRootSize);
      
      // Find the target and set the appropriate data value
      switch(e.target.getAttribute('name')) {

        case 'root_size':
            this.setState({
              rootSize: parsedSize 
            },  () => {
              this.setState({
                remSize: (this.state.pxSize / this.state.rootSize).toFixed(2)
              })
            });
            break;
        
        case 'required_size':
          this.setState({
            pxSize: parsedSize
          }, () => {
            this.setState({
              remSize: (this.state.pxSize / this.state.rootSize).toFixed(2)
            })
          });
          break;
      }

      
  
  }


  render() {
    return (
      <div>
        <header>
        <NavLink to="/"><img src="/img/remtopx-logo.png" className="logo" alt="RemtoPx Converter Logo" /></NavLink>
    
        <nav className="menu">
          <label htmlFor="toggle">&#9776;</label>
          <input type="checkbox" id="toggle" />
            <div id="dd-menu">
            <Navigation />
            </div>
          </nav>

          </header>

      

        <div className="container">
          <h1 className="title">PX to REM Converter</h1>
          <h2>Convert your Pixel Value to REM</h2>
          <h3>Default Root Font Size (px) </h3>
          <input type="number" name="root_size" id="root-size" placeholder="16" onChange={this.calculate}/>
        </div>

      <div className="container">
        <div className="converter-container">

        <label>Select or Type your Pixels (px)
          <div className="numeric">
            <input type="number" name="required_size" id="required-size" className="input" placeholder="16" onChange={this.calculate} />
            <abbr className="rem-unit">px</abbr>
          </div>
        </label>

        <div className="equals"> = </div>
        <label>Result in Rem
          <div className="numeric">
            <input id="red" value={this.state.remSize}  className="input" />
            <abbr className="px-unit">rem</abbr>
          </div>
        </label>
        </div>

        <NavLink to="/"><img src="/img/switch.png" id="large-switch"  alt="Switch Logo" /></NavLink>

        <p>Click the <NavLink to="/"><img src="/img/switch.png" id="small-switch"  alt="Switch Logo" /></NavLink> icon to switch to <b>REM to PX Converter.</b></p>

      </div>
      <Footer />
    </div>
    );
  }
}

export default PXConverter;
