
import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import About from './About.js'
import Contact from './Contact.js'
import Home from './Home.js'
import Error from './Error.js'
import PXConverter from './Switch.js'
import Terms from './Terms.js'
import Privacy from './Privacy.js'
import Disclaimer from './Disclaimer.js'



class App extends React.Component {



  render() {
    return (
      <BrowserRouter>
      <div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/px-to-rem-converter" component={PXConverter}/>
            <Route path="/terms" component={Terms} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route component={Error} />
          </Switch>
      </div>
  </BrowserRouter>
    );
  }
}

export default App;
