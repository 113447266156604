import React, { Component } from 'react';
import Navigation from './Navigation.js'
import { NavLink } from 'react-router-dom';
import Footer from './Footer.js'

import './App.css';


class About extends Component {
  
  render() {
    return (
      <div>
        <header>
        <NavLink to="/"><img src="/img/remtopx-logo.png" className="logo" alt="RemtoPx Converter Logo" /></NavLink>
        
            <nav className="menu">
            <label htmlFor="toggle">&#9776;</label>
            <input type="checkbox" id="toggle" />
                <div id="dd-menu">
                <Navigation />
                </div>
            </nav>
         </header>

         <div className="container">
          <h1 className="title">About</h1>
          <div>
            <p>This <b>Rem to PX converter</b> is a tool that allows you to convert your rem units into pixels (px).</p>
            <p><b>REM</b> stands for root em and is essentially the root element's font size. It is always relative to the font-size of the root html element.</p>
            <p><b>Pixels (px)</b> is a unit of length that corresponds to the width or height of a single dot. It is an absolute unit. </p>

            <p>You can switch from rem to pixels easily. </p>

            <h2>Click below to see our other generators and tools:</h2>

            <p><b>Progress Bar Generator</b> - <a href="https://progressbargenerator.com/" target="_blank">progressbargenerator.com</a> </p>

            <p><b>Hamburger Menu Generator</b> - <a href="https://hamburgermenugenerator.com/" target="_blank">hamburgermenugenerator.com</a> </p>

<p><b>CSS Circle Generator</b> - <a href="https://csscirclegenerator.com/" target="_blank">csscirclegenerator.com</a> </p>



            <h2>How to Use the REM to PX Converter</h2>

            <p><b>Step 1:</b> Enter your base font size underneath "Default Root Font Size (px)". This is the font-size you declare on an element or the root html.</p>
            <p><b>Step 2:</b> Input the rem value in the input field underneath "Select or Type your Rem". This is the REM that you want to convert.</p>
            <p><b>Step 3:</b> The result in pixels (px) will be displayed instantly on right underneath "Result in Pixels".</p>
            <br />
             <p><b>Note*:</b> You can also switch from the Rem to PX Converter to a <b>Pixels to Rem Converter</b> and convert from pixels to rem.</p>   
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;