import React, { Component } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import { NavLink } from 'react-router-dom';
import Footer from './Footer.js'

class Error extends Component {
  


  render() {
    return (
    <div>
        <header>
            <NavLink to="/"><img src="/img/remtopx-logo.png" className="logo" alt="RemtoPx Converter Logo" /></NavLink>
              <nav className="menu">
                <label htmlFor="toggle">&#9776;</label>
                <input type="checkbox" id="toggle" />
                <div id="dd-menu">
                    <Navigation />
                </div>
            </nav>
        </header>
        <div className="container">
          <h1 className="title">Error: Page Not Found</h1>
        </div>
        <Footer />
    </div>
    );
  }
}

export default Error;