import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './App.css';


class Navigation extends Component {
  
  render() {
    return (
    <nav className="menu">
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink>
    </nav>
    );
  }
}

export default Navigation;